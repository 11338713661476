.single-select {
  width: 100%;
}

.date-picker {
  width: 100%;
  min-height: 3.8rem;
  font-size: 1.3rem;
}

.popover-header,
.popover-body {
  font-size: 1.3rem;
}

.Select {
  width: 444px !important;
  margin-right: 5px;
  z-index: 0 !important;
}

.Select.is-open {
  z-index: 1 !important;
}

.Select-menu {
  position: fixed !important;
  width: 440px !important;
  margin: 1px;
}

.date-input  {
  position: relative;
  border: none;
  width: 150px;
  height: 25px;
  color: white;
}

.date-input:before {
  position: absolute;
  // top: 3px;
  top: 10%;
  left: 10px;
  content: attr(data-date);
  display: inline-block;
  color: black;
}

.date-input::-webkit-datetime-edit,
.date-input::-webkit-inner-spin-button,
.date-input::-webkit-clear-button {
  display: none;
}

.date-input::-webkit-calendar-picker-indicator {
  position: absolute;
  /* top: 3px; */
  right: 0;
  color: black;
  opacity: 1;
}

div.text__ellipsis, p.text__ellipsis{
  display: inline-block;
  position: initial;
  width: 90%;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  padding: 0 7px;
}

.react__tool__tip__custom__class{
  max-width: 60vw !important;
}